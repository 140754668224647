@import '../../../../../styles/variables.scss';

.nav-member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: $dark;
  z-index: 200;
  position: relative;
  @media only screen and (min-width: $md-screen) {
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 260px;
    height: 100vh;
    padding: 20px;
  }
  .back {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: flex;
    }
    border-top: 1px solid rgba($white , 0.4);
    width: 100%;
    justify-content: center;
    a {
      color: $white;
      padding: 10px 0px;
      display: flex;
      align-items: center;
      font-family: "Raleway-Bold";
      text-transform: uppercase;
      text-decoration: none;
      svg {
        fill: $primary;
        transform: translateX(-10px);
      }
    }
  }
  .logo {
    @media only screen and (min-width: $md-screen) {
      width: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      height: 34px;
      @media only screen and (min-width: $md-screen) {
        height: 56px;
      }
    }
  }
  .links {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 60px ;
      margin-left: 20px;
    }
    a , button {
      font-family: "Raleway-Bold";
      text-decoration: none;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
      p {
        @include transition;
        margin: 0px 10px;
        color: rgba($white , 0.4);
        font-size: 18px;
        padding-bottom: 2px;
        &:hover {
          color: rgba($white , 0.8);
        }
      }
      .puce {
        @include transition;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: rgba($primary , 0);
        transform: translate(-20px , -1px);
      }
      &.link-is-active {
        p {
          color: $white;
        }
        .puce {
          background-color: rgba($primary , 1);
          transform: translate(0px , -1px);
        }
      }
    }
  }
  .user {
    display: none;
    @media only screen and (min-width: $md-screen) {
      width: 220px;
      display: flex;
      align-items: center;
      margin: 20px 0px 10px 0px;
      padding: 10px 0px;
      border-top: 1px solid rgba($white , 0.4);
      border-bottom: 1px solid rgba($white , 0.4);
      svg {
        margin-right: 10px;
      }
    }
  }
  .select-entity {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
    }
  }
  .mobile-toggle {
    display: flex;
    align-items: center;
    p {
      color: $white;
      margin-right: 10px;
    }
    @media only screen and (min-width: $md-screen) {
      display: none;
    }
  }
}