@import './../../../styles/variables.scss';

.switch {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.login {
  overflow: auto;
  width: 100%;
  min-height: 100vh;
  display: flex;
  .col-form {
    width: 100%;
    background-color: $white;
    min-height: 100%;
    padding: $margin-md;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      width: 50%;
    }
    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 230px;
      }
    }
    .form-reset {
      &.message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin: $margin-md
        }
      }
    }
    form {
      &.form-reset {
        @include animation-mixin-y(slideInLogin, 50px, 0);
        animation-name: slideInLogin;
        animation-duration: 0.2s;
      }
      &.form-login {
        @include animation-mixin-y(slideIn, 50px, 0);
        animation-name: slideIn;
        animation-duration: 0.2s;
      }
      width: 90%;
      max-width: 400px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .eye {
        svg {
          float: right;
          transform: translate(-20px , -58px)
        }
        &-reset {
          svg {
            float: right;
            transform: translate(-20px , -30px)
          }
        }
      }
      .btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn {
          &.primary {
            min-width:200px;
            padding: 15px 15px;
          }
        }
        .btn-reset {
          margin:$margin-md;
          color:$dark;
          text-decoration: underline;
        }
      }
    }
    .back {
      display: flex;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        svg {
          color: $primary
        }
        p {
          margin-left: 10px;
          color: $dark;
        }
      }
    }

  }
  .col-img {
    display: none;
    background-color: $dark;
    @media only screen and (min-width: $md-screen) {
      display: block;
      width: 50%;
      background-image: url('../../../assets/images/login.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 85%;
    }
  }
}
