@import '../../../../../styles/variables.scss';

.back {
  text-decoration: none;
  color: $dark;
  display: flex;
  align-items: center;
  margin: 10px;
  svg {
    fill: $primary;
    margin-right: 5px;
  }
}

.row {
  @include row;
  .info {
    p {
      margin-bottom: 20px;
    }
  }
}

.container {
  width: 100%;
  overflow-y: auto;
  @media only screen and (min-width: $sm-screen) {
    overflow-y: hidden;
  }
  .header {
    background-color: $grey;
    width: 100%;
    padding-bottom: 0px;
    button {
      margin-bottom: 10px;
    }
  }
  .content {
    max-width: 1200px;
    padding: 0px 20px;
    margin: auto;
    .case {
      padding-top: 10px;
      max-width: 100%;
      .col-2 {
        padding-right: 20px;
      }
      button {
        width: 200px;
        display: flex;
        text-align: left;
        color: $primary;
        font-family: "Raleway-Bold";
        svg {
          margin-right: 5px;
        }
      }
      label {
        font-family: "Raleway-Regular";
        color: rgba($dark, 0.3);
      }
      p {
        margin-top: 8px;
        font-family: "Raleway-Bold";
      }
      .users {
        display: flex;
        .user {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $primary;
          color: $white;
          font-size: 12px;
          margin: 8px 8px 0px 0px;
          width: 34px;
          height: 34px;
          border-radius: 30px;
          p {
            margin-top: 0px;
          }
        }
      }
    }
    .entities {
      z-index: 20;
      display: flex;
      background-color: transparent;
      margin: 20px 0px;
      @media only screen and (min-width: $sm-screen) {
        background-color: $white;
        transform: translateY(30px);
        justify-content: space-between;
        align-items: center;
        margin: 0px;
      }
      .add {
        z-index: 22;
        position: fixed;
        top: calc(100vh - 130px);
        right: 20px;
        @media only screen and (min-width: $sm-screen) {
          transform: translateX(0px);
          position: relative;
          top: 0px;
          right: 0px;
        }
        button {
          margin: 4px;
          width: 40px;
          height: 40px;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        @media only screen and (min-width: $sm-screen) {
          flex-direction: row;
          overflow-y: auto;
          position: relative;
          margin: 0px;
        }
        .entity {
          @include transition;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Raleway-Bold";
          margin: 5px 0px;
          opacity: 0.3;
          width: auto;
          cursor: pointer;
          height: 30px;
          @media only screen and (min-width: $sm-screen) {
            justify-content: flex-start;
            margin: 0px 20px;
            height: 36px;
          }
          button {
            width: auto;
            margin: 4px;
            width: 20px;
            height: 20px;
            // display: none;
            @media only screen and (min-width: $sm-screen) {
              width: 30px;
              height: 30px;
            }
          }
          &.active {
            opacity: 1;
            font-size: 18px;
            @media only screen and (min-width: $sm-screen) {
              font-size: 16px;
              border-bottom: 1px solid $primary;
            }
          }
          p {
            @include transition;
            margin: 0px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 20px;
          }
          svg {
            color: $primary;
            font-size: 16px;
            transform: translateY(4px);
          }
        }
      }
    }
  }
  .main {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    @include transition;
    opacity: 0;
    &.slide-in {
      opacity: 1;
    }
    @media only screen and (min-width: $sm-screen) {
      overflow-y: auto;
      margin-top: 40px;
    }
    .content {
      position: relative;
      width: 100%;
      margin-top: 0px;
      padding: 20px;
      @media only screen and (min-width: $sm-screen) {
        margin-top: 10px;
        padding: 10px 40px;
      }
      .label {
        font-family: "Raleway-Regular";
        color: rgba($dark, 0.3);
      }
    }
  }
  .contact {
    background-color: $dark;
    color: $white;
    width: 100%;
    padding-bottom: 30px;
    padding: 20px;
    border-radius:  15px;
    @media only screen and (min-width: $sm-screen) {
      position: absolute;
      width: 300px;
      right: 20px;
    }
    h3 {
      font-family: "Raleway-Bold";
      font-size: 18px;
    }
    p, a {
      @include transition;
      display: flex;
      margin-top: 20px;
      color: $white;
      text-decoration: none;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      &:hover {
        color: $primary;
      }
    }
    .btn-meet {
      width: 100%;
      text-transform: uppercase;
      margin-top: 20px;
      @media only screen and (min-width: $sm-screen) {
        margin-top: 40px;
        width: calc(300px - 40px);
        position: absolute;
      }
    }
  }
  section {
    h3 {
      font-family: "Raleway-Bold";
      margin-bottom: 20px;
      font-size: 18px;
    }
    a {
      color: $primary;
    }
    border-bottom: 1px solid $grey;
    margin: 20px 0px;
    padding-bottom: 30px;
    .note {
      svg {
        fill: $primary;
        transform: translate(3px, 3px);
      }
    }
    .quotes {
      display: flex;
      flex-wrap: wrap;
      .quote {
        @include transition;
        background-color: $grey;
        padding: 8px 20px;
        color: $dark;
        text-decoration: none;
        border-radius: $radius;
        margin-right: 10px;
        margin-bottom: 10px;
        &:hover {
          background-color: rgba($primary, 0.3);
        }
      }
      .add-quote {
        @include transition;
        background-color: $primary;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        text-decoration: none;
        border-radius: $radius;
        width: 34px;
        margin-right: 10px;
        margin-bottom: 10px;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.list-row {
  width: 100%;
  display: flex;
  margin: 10px 0px;
  justify-content: flex-start;
  align-items: center;
  .col {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    @media only screen and (min-width: $sm-screen) {
      width: 33%;
      padding: 0px 0px 0px 20px;
    }
    .download {
      margin-right: 10px;
      transform: translateY(2px);
    }
    &.delete {
      display: flex;
      justify-content: flex-end;
      padding: 0px 10px;
      button {
        display: flex;
        font-size: 16px;
        align-items: center;
        opacity: 0.3;
        svg {
          margin-left: 8px;
        }
        &:hover {
          opacity: 1;
          color: red;
        }
      }
    } 
  }
  &.sort {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: flex;
    }
  }
  &.list-item {
    @include transition;
    padding: 10px 0px;
    color: $dark;
    text-decoration: none;
    height: auto;
    width: 100%;
    background-color: $grey;
    border: 1px solid $grey;
    flex-direction: column;
    border-radius: 5px;
    @media only screen and (min-width: $sm-screen) {
      flex-direction: row;
      min-height: 50px;
    }
  }
}

.delete-modale {
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    margin: 10px;
  }
  p {
    margin-bottom: 20px;
  }
}

