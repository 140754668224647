@import '../../../styles/variables.scss';

.container {
  width: 100%;
  @media only screen and (min-width: $sm-screen) {
    width: 500px;
  }
  h2 {
    font-family: "Raleway-Bold";
    margin-bottom: 20px;
    text-align: center;
  }
  .actions {
    display: flex;
    justify-content: center;
    button {
      @media only screen and (min-width: $sm-screen) {
        width: 220px;
        margin: 10px;
      }
    }
  }
}