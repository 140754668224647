@import '../../../../styles/variables.scss';

.account {
  padding: 20px;
  @media only screen and (min-width: $xs-screen) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: $md-screen) {
    display: flex;
    align-items: flex-start;
    background-image: url('../../../../assets/images/informations.png');
    background-repeat: no-repeat;
    background-position: 85% 40%;
    background-size: 45%;
  }
  form {
    width: 100%;
    @media only screen and (min-width: $xs-screen) {
      width: 300px;
    }
    @media only screen and (min-width: $md-screen) {
      margin-left: 10%;
    }
    h1 {
      margin-bottom: 20px;
    }
    button {
      margin-top: 20px;
    }
  }
}