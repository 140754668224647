@import '../../../../../styles/variables.scss';

.header {
  position: fixed;
  width: 100%;
  padding: 0px 20px 0px 20px;
  max-width: 1200px;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc(100% - 40px);
    padding: 20px 20px 0px 20px;
  }
  .row {
    @include row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #C6CBD8;
    padding-bottom: 20px;
    .col-3 {
      padding-right: 20px;
    }
  }
}

.list {
  padding: 0px 20px;
  margin-top: 130px;
  width: 100%;
  z-index: 0;
  max-width: 1200px;
  height: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  @media only screen and (min-width: $md-screen) {
    margin-top: 130px;
    height: calc(100% - 140px);
  }
  .content {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

.list-row {
  width: 100%;
  display: flex;
  margin: 10px 0px;
  justify-content: flex-start;
  align-items: center;
  .col {
    width: 100%;
    padding: 0px 20px;
    @media only screen and (min-width: $sm-screen) {
      width: 20%;
    }
    &.state {
      @media only screen and (min-width: $sm-screen) {
        width: calc(60% / 4);
      }
    }
  }
  &.sort {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: flex;
    }
  }
  &.list-item {
    @include transition;
    padding: 10px 0px;
    color: $dark;
    text-decoration: none;
    height: auto;
    width: 100%;
    background-color: $grey;
    border: 1px solid $grey;
    flex-direction: column;
    border-radius: 5px;
    &:hover {
      background-color: rgba($primary, 0.3);
    }
    @media only screen and (min-width: $sm-screen) {
      flex-direction: row;
      min-height: 50px;
      transform: translateX(5px);
    }
  }
}