@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height:  auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: $sm-screen) {
    width: 500px;
  }
  h2 {
    font-family: "Raleway-Bold";
    margin-bottom: 20px;
    text-align: center;
  }
  .row {
    width: 100%;
    &.top {
      margin-top: 30px;
    }
    &.calendar {
      position: relative;
      margin-bottom: 20px;
    }
    .col-2 {
      width: calc(50% - 10px);
    }
    .col-1 {
      width: 100%;
    }
  }
}