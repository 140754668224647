@import '../../../../../styles/variables.scss';

.header {
  position: absolute;
  width: 100%;
  padding: 20px 20px 0px 20px;
  max-width: 1200px;
  @media only screen and (min-width: $md-screen) {
    width: calc(100% - 40px);
  }
  .row {
    @include row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #C6CBD8;
    padding-bottom: 20px;
    @media only screen and (min-width: $sm-screen) {
      .col-4 {
        width: 33%;
        margin-right: 20px;
      }
    }
    .add {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        padding: 10px 20px;
      }
      @media only screen and (min-width: $sm-screen) {
        margin-top: 0px;
        justify-content: flex-end;
        button {
          margin-left: 20px;
          span {
            display: none;
          }
        }
      }
      @media only screen and (min-width: $md-screen) {
        button {
          min-width: 140px;
          max-width: 200px;
          padding: 10px;
          span {
            display: inline;
          }
        }
      }
      svg {
        margin-right: 10px;
        transform: translateY(1px);
      }
    }
  }
}

.list {
  padding: 0px 20px 0px 20px;
  margin-top: 310px;
  width: 100%;
  max-width: 1200px;
  height: calc( 100% - 310px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  @media only screen and (min-width: $sm-screen) {
    margin-top: 100px;
    height: calc( 100% - 110px);
  }
  @media only screen and (min-width: $md-screen) {
    margin-top: 130px;
    height: calc( 100% - 30px);
  }
  .content {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

.list-row {
  width: 100%;
  display: flex;
  margin: 10px 0px;
  justify-content: flex-start;
  align-items: center;
  .col {
    width: 100%;
    padding: 0px 20px;
    @media only screen and (min-width: $sm-screen) {
      width: 25%;
    }
  }
  .users {
    width: 100%;
    padding: 0px 20px;
    @media only screen and (min-width: $sm-screen) {
      width: 25%;
    }
  }
  .entities {
    width: 100%;
    padding: 0px 20px;
    @media only screen and (min-width: $sm-screen) {
      width: 50%;
    }
  }
  &.sort {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: flex;
    }
  }
  &.list-item {
    @include transition;
    padding: 10px 0px;
    color: $dark;
    text-decoration: none;
    height: auto;
    width: 100%;
    background-color: $grey;
    border: 1px solid $grey;
    flex-direction: column;
    border-radius: 5px;
    cursor: pointer;
    .entities {
      display: flex;
      p {
        padding: 6px 10px 6px 0px;
      }
      p:not(:last-child) {
        margin-right: 10px;
        border-right: 1px solid rgba($dark , 0.3);
      }
    }
    .users {
      display: flex;
      justify-content: flex-end;
      .user {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Raleway-Bold";
        background-color: $primary;
        color: $white;
        font-size: 12px;
        margin: 0px 0px 0px 8px;
        width: 34px;
        height: 34px;
        border-radius: 30px;
        p {
          margin-top: 0px;
        }
      }
    }
    &:hover {
      background-color: rgba($primary, 0.3);
    }
    @media only screen and (min-width: $sm-screen) {
      flex-direction: row;
      min-height: 50px;
      transform: translateX(5px);
    }
  }
}