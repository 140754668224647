@import '../../../../styles/variables.scss';

.container {
  @media only screen and (min-width: $md-screen) {
    background-image: url('../../../../assets/images/informations.png');
    background-repeat: no-repeat;
    background-position: 95% 30%;
    background-size: 43%;
  }
  .account {
    width: 100%;
    @media only screen and (min-width: $xs-screen) {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media only screen and (min-width: $md-screen) {
      display: flex;
      align-items: flex-start;
    }
    form {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: 50%;
      }
      .row {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          flex-direction: row;
          justify-content: space-between;
        }
        .col {
          width: 100%;
          @media only screen and (min-width: $md-screen) {
            width: calc(50% - 10px);
          }
        }
      }
      .btns {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        button {
          margin-bottom: 20px;
        }
      }
      h1 {
        margin-bottom: 20px;
      }

      button {
        margin-top: 20px;
      }

    }
  }
}