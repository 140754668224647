@import '../../../../../../styles/variables.scss';

.container {
  width: 100%;
  @media only screen and (min-width: $sm-screen) {
    width: 50%;
    padding-right: 30px;
  }
  label {
    font-size: 14px;
  }
  .select {
    max-width: 360px;
  }
  .input {
    max-width: 220px;
    margin-bottom: 15px;
  }
  .options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;;
    .btn {
      @include transition;
      background-color: #F2F4FB;
      border-radius: $radius;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #F2F4FB;
      &.is-active {
        background-color: $dark;
        color: $white;
      }
      &:hover {
        border: 1px solid $dark;;
        @media only screen and (min-width: $sm-screen) {
          transform: scale(1.03)
        }
      }
    } 
  }
}