@import '../../../styles/variables.scss';

.date-picker {
  margin-top: 5px;
  border: 1px solid #C6CBD8;
  transition: all 0.2s ease-in-out;
  background: $white;
  border-radius: 5px;
  height: 41px;
  padding: 4px 16px;
  &:hover {
    border: 1px solid $primary;
  }
  input:focus {
    outline: none;
    background-color: transparent;
  }
  .MuiInput {
    background-color: $white;
    font-size: 14px;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiFormHelperText-root {
    transform: translate(-10px , 40px);
    position: absolute;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormLabel-root {
    font-family: "Ubuntu-Regular";
    font-size: 14px;
  }
  .MuiPickerDTTabs-tabs {
    background-color: $dark;
  }
  .MuiPickersDay-daySelected {
    color: #fff;
    font-weight: 500;
    background-color: $dark;
  }
  .MuiPickersCalendarHeader-iconButton {
    z-index: 1;
    background-color: red !important;
  }
  .MuiIconButton-root {
    padding: 0px;
  } 
  svg {
    fill: rgba($dark , 0.5 );
    &:hover {
      fill: $primary;
    }
  }
}
.error-message {
  margin: 0px;
  color: $error;
  min-height: 20px;
  padding-top: 3px;
  font-size: 12px;
}