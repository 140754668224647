@import '../../../../../styles/variables.scss';

.nav {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  .logo {
    @media only screen and (min-width: $md-screen) {
      width: 220px;
    }
    img {
      height: 34px;
      @media only screen and (min-width: $md-screen) {
        height: 45px;
      }
    }
  }
  .links {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      align-items: center;
    }
    a {
      font-family: "Raleway-Bold";
      text-decoration: none;
      font-weight: bold;
      p {
        @include transition;
        margin: 0px 20px;
        color: rgba($white , 0.4);
        padding-bottom: 2px;
        &:hover {
          border-bottom: 1px solid rgba($white , 0.8);
        }
      }
      &.link-is-active {
        p {
          color: $white;
          border-bottom: 1px solid $primary;
        }
      }
    }
  }
  .user {
    display: none;
    @media only screen and (min-width: $md-screen) {
      width: 220px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
  }
  .mobile-toggle {
    display: flex;
    align-items: center;
    p {
      color: $white;
      margin-right: 10px;
    }
    @media only screen and (min-width: $md-screen) {
      display: none;
    }
  }
}