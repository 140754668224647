@import './variables.scss';

body, html {
  height: 100%;
}

#root {
  scrollbar-color: #D8D8D8  #FFFFFF !important;
  scrollbar-width: 5px !important;
  @media screen and (max-width: $sm-screen) {
    height: auto;
    scrollbar-color: #D8D8D8  transparent !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent ; 
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #D8D8D8 ; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary; 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  color: $dark;
  font-size: 16px;
  font-family: "Raleway-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.page-container {
  background-color: $dark;
  position: relative;
  width: 100%;
  min-height: 100vh;
  // @media only screen and (min-width: $md-screen) {
  //   padding-top: 100px;
  // }
  .page-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    border-radius: 10px;
    background-color: $white;
    @media only screen and (min-width: $md-screen) {
      margin: 0px 20px ;
      height: calc(100vh - 100px);
      width: calc(100% - 40px);
    }
    &.member {
      padding: 20px;
      overflow-y: auto;
      @media only screen and (min-width: $md-screen) {
        margin: 20px;
        padding: 40px;
        left: 240px;
        height: calc(100vh - 40px);
        width: calc(100% - 280px);
        z-index: 0;
        align-items: flex-start;
      }
      &.quote {
        padding: 0px;
      }
      h1 {
        width: 100%;
        font-size: 32px;
        font-family: "Raleway-Bold";
      }
    }
    .content {
      width: 100%;
      padding: 20px;
      max-width: 1200px;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.semi-bold {
  font-family: "Raleway-SemiBold";
}

.bold {
  font-family: "Raleway-Bold";
}

.regular {
  font-family: "Raleway-Regular";
}

label {
  font-family: "Raleway-Bold";
  font-weight: bold;
  font-size: 14px;
}

button {
  @include btn;
}

.m-md {
  margin: $margin-md
} 

.text-center {
  text-align: center;
}

.text-success {
  color: $success;
}

.text-grey {
  color: rgba($dark, 0.25);
}

.text-error {
  font-size: $small;
  margin: 6px;
  min-height: 16px;
  color: $error;
}

.btn {
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: $radius;
  font-size: 14px;
  font-family: "Raleway-Bold";
  text-transform: uppercase;
  &.primary {
    color: $white;
    text-decoration: none;
    background-color: $primary;
    &:hover {
      background-color: $primary-dark;
    }
  }
}

input {
  font-family: "Raleway-Regular";
  border-radius: 5px;
  padding: 11px;
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid $primary;
  background-color: $white;
  transition: all 0.2s ease-in-out;
  border: 1px solid $grey-light;
  &:focus {
    outline: none;
    background-color: $grey-extra-light;
  }
  &.login {
    background-color: $grey-extra-light;
    border: none;
  }
}


.filters {
  padding-bottom: 15px;
  margin-bottom: 15px;
  z-index: 200;
  @media only screen and (min-width: $sm-screen) {
    border-bottom: 1px solid rgba($dark, 0.25);
  }
  .col-3 {
    margin-right: 20px;
  }
}

.bg-grey {
  background-color: #F2F4FB;
}

.radius {
  border-radius: 5px;
}
