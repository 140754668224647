@import '../../../styles/variables.scss';

.container {
  display: flex;
  background: #F2F4FB;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(219, 221, 228);
    border-radius: 10px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
  &.anim {
    animation: anim 0.2s ;
    @include animation-mixin-x(anim , -100px , 0);
  }
  .day {
    width: 102px;
    min-width: 102px;
    padding: 20px;
    text-align: center;
    @include transition;
    p {
      margin: 0px;
      text-align: center;
      margin-bottom: 10px;
    }
    .btn {
      width: 100%;
      background-color: $white;
      margin: 5px 0px;
      padding: 5px 0px;
      border-radius: 5px;
      font-size: 14px;
      border: 1px solid $white;
      &:hover {
        border: 1px solid $dark;
      }
      &.is-active {
        background-color: $dark;
        color: $white;
      }
    }
  }
}

.arrows {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  svg {
    color: $primary
  }
}