@import '../../../styles/variables.scss';

.container {
  width: 100%;
  @media only screen and (min-width: $sm-screen) {
    width: 600px;
  }
  h2 {
    font-family: "Raleway-Bold";
    margin-bottom: 20px;
    text-align: center;
  }
  .contact {
    background-color: $dark;
    width: calc(100% + 60px);
    padding: 30px;
    transform: translate(-30px , 30px);
    .row {
      @include row;
      @media only screen and (min-width: $md-screen) {
        justify-content: space-between;
      }
      .col-2 {
        @media only screen and (min-width: $md-screen) {
          width: calc(50% - 10px)
        }
      }
    }
    label {
      color: $white;
    }    
  }
  .actions {
    display: flex;
    justify-content: center;
    button {
      @media only screen and (min-width: $sm-screen) {
        width: 220px;
        margin: 10px;
      }
    }
  }
}