@import '../../../../../../styles/variables.scss';

.nav {
  position: fixed;
  z-index: 10;
  top: 60px;
  right: 0px;
  width: 100%;
  height: 100vh;
  background-color: $dark;
  transform: translateX(100%);
  @include transition;
  padding: 20px;
  @media only screen and (min-width: $xs-screen) {
    width: 300px;
  }
  @media only screen and (min-width: $md-screen) {
    display: none;
  }
  &.is-active {
    transform: translateX(0px)
  }
  .user {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    .notif {
      display: flex;
      align-items: center;
      color: $white;
      svg {
        margin: 10px 10px;
      }
    }
  }
  .links {
    margin: 20px;
    a {
      font-family: "Raleway-Bold";
      text-decoration: none;
      font-weight: bold;
      .link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .puce {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 10px;
        background-color: $primary;
        opacity: 0;
      }
      p {
        @include transition;
        font-size: 24px;
        margin: 20px 0px;
        color: rgba($white , 0.4);
        padding-bottom: 2px;
      }
      &.link-is-active {
        p {
          color: $white;
        }
        .puce {
          opacity: 1;
        }
      }
    }
  }
}