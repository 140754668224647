@import './mixins.scss';

$bg-color: #F5F6F8;
$white: #FFFFFF;
$dark: #15284B;
$primary: #4BC3C9;
$primary-dark: rgb(68, 178, 184);
$grey-extra-light: #F2F4FB;
$grey-light: #C6CBD8;
$grey: #F2F4FB;

$success: #49aa56;
$error : red;
$danger : red;

$shadow: 0px 0px 13px -2px rgba(156,167,177,0.30);

$card-shadow: 0px 18px 32px -4px rgba(136,136,136,0.25);

$shadow-hover: 0px 0px 10px -2px rgba(80, 80, 80, 0.603);

$lg-screen: 1200px;
$md-screen: 1024px;
$sm-screen: 768px;
$xs-screen: 576px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$small : 13px;
$regular : 16px;
$h2 : 18px;
$h1 : 25px;

$radius: 6px;

@font-face {
	font-family: "Raleway-Regular";
	src: url("../assets/fonts/Raleway-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway-SemiBold";
	src: url("../assets/fonts/Raleway-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway-Bold";
	src: url("../assets/fonts/Raleway-Bold.ttf") format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
}