@import '../../../../styles/variables.scss';

.textarea {
  width: 100%;
  max-width: 100%;
  textarea {
    @include transition;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-family: "Raleway-Regular";
    min-height: 200px;
    padding: 14px;
    font-size: 16px;
    margin-bottom: 10px;
    border: 1px solid $dark;
    background: $grey;
    border-radius: $radius;
    &:focus {
      outline: none;
      border: 1px solid $primary;
    }
  }
  .error-message {
    margin: 0px;
    color: $error;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
}