@import '../../../styles/variables.scss';

.container {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba($white , 0.4);
  .entity {
    font-family: "Raleway-Bold";
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      @include btn;
      color:rgba($white , 0.4);
      font-size: 20px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      &:hover {
        background-color: #0e1b34;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .select {
    overflow: hidden;
    @include transition;
    a {
      padding: 5px 0px;
      font-size: 14px;
      display: block;
      font-family: "Raleway-Bold";
      color: rgba($white , 0.4);
      text-decoration: none;
      &:hover {
        color: rgba($white , 0.6);
      }
    }
  }
}