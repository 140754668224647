@import '../../../styles/variables.scss';

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a {
    text-decoration: none;
  }
  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $white;
    &:hover {
      @include transition;
      color: $primary;
      .avatar {
        background-color: $primary;
        color: $white;
      }
    }
    .avatar {
      @include transition;
      width: 38px;
      height: 38px;
      border-radius: 20px;
      margin-right: 10px;
      background-color: rgba($grey-light, 0.25);
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
    }
    p {
      margin: 0px;
    }
  }
}