@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  .documents {
    margin-top: 20px;
    .doc {
      background-color: $grey;
      margin: 5px 0px;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (min-width: $sm-screen) {
        margin: 5px 20px 5px 0px;
      }
      a {
        color: $dark;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      svg {
        opacity: 0.5;
        cursor: pointer;
        &:hover {
          fill: $danger;
          opacity: 1;
        }
      }
    }
  }
  .list-row {
    width: 100%;
    display: flex;
    margin: 10px 0px;
    justify-content: flex-start;
    align-items: center;
    a {
      color: $primary;
      &:hover {
        text-decoration: underline;
      }
    }
    .col {
      width: 100%;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      text-decoration: none;
      @media only screen and (min-width: $sm-screen) {
        width: 33%;
        padding: 0px 0px 0px 20px;
      }
      .download {
        margin-right: 10px;
        transform: translateY(2px)
      }
      
    }
    &.list-item {
      @include transition;
      padding: 10px 0px;
      color: $dark;
      text-decoration: none;
      height: auto;
      width: 100%;
      background-color: $grey;
      border: 1px solid $grey;
      flex-direction: column;
      border-radius: 5px;
      @media only screen and (min-width: $sm-screen) {
        flex-direction: row;
        min-height: 50px;
      }
    }
  }
}