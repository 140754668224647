@import '../../../../../styles/variables.scss';

.container {
  background-color: $grey;
  width: 100%;
  padding: 0px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  @media only screen and (min-width: $md-screen) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info {
    width: 100%;
    @media only screen and (min-width: $md-screen) {
      width: 50%;
    }
    p {
      margin: 20px 0px;
      width: 260px;
      @media only screen and (min-width: $md-screen) {
        margin: 15px 0px;
        width: 100%;
      }
    }
  }
}