@mixin btn {
  text-align: center;
	cursor: pointer;
	font-family: "Raleway-Regular";
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin slideInLogin {
	@include animation-mixin-y(slideIn, 30px, 0);
	animation-name: slideIn;
  animation-duration: 0.2s;
}

@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin col-1 {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  padding-top: 20px;
  @media only screen and (min-width: $sm-screen) {
    padding-top: 0px;
  }
}

@mixin row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  @media only screen and (min-width: $sm-screen) {
    flex-direction: row;
  }
  .col-1 {
    @include col-1();
  }
  .col-2 {
    @include col-1();
    @media only screen and (min-width: $sm-screen) {
      width: 50%;
    }
  }
  .col-3 {
    @include col-1();
    @media only screen and (min-width: $sm-screen) {
      width: 33.33%;
    }
  }
  .col-4 {
    @include col-1();
    @media only screen and (min-width: $sm-screen) {
      width: 25%;
      max-width: 25%;
    }
  }
}