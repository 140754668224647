@import '../../../../styles/variables.scss';

.row {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (min-width: $md-screen) {
    flex-direction: row;    
    justify-content: flex-start;
    align-items: center;
  }
  .card {
    width: 100%;
    min-height: 280px;
    border: 1px solid $dark;
    border-radius: $radius;
    text-decoration: none;
    margin-bottom: 20px;
    @include transition;
    display: flex;
    @media only screen and (min-width: $md-screen) {
      width: calc(33.33%);
      margin-right: 30px;
      min-height: 100%;
      flex-direction: column;
    }
    &:hover {
      transform: scale(1.05);
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.3
    }
    .image {
      width: 50%;
      height: 100%;
      position: relative;
      @media only screen and (min-width: $md-screen) {
        width: 100%;
        max-height: 250px;
      }
      p {
        position: absolute;
        width: 100%;
        text-align: center;
        transform: translateY(45px);
        color: $white;
        font-size: 22px;
        @media only screen and (min-width: $md-screen) {
          font-size: 28px;
        }
      }
      img {
        height: 100%;
        width: 100%;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;;
        overflow: hidden;
        object-fit: cover;
        @media only screen and (min-width: $md-screen) {
          max-height: 250px;
          width: 100%;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;;
        }
      }
    }
    .text {
      width: 50%;
      padding: 20px;
      color: $dark;
      text-decoration: none;
      @media only screen and (min-width: $md-screen) {
        width: 100%;
      }
      h3 {
        margin-bottom: 10px;
      }
    }
  }
}
