@import '../../../../../styles/variables.scss';

.back {
  text-decoration: none;
  color: $dark;
  display: flex;
  align-items: center;
  padding: 10px;
  svg {
    fill: $primary;
    margin-right: 5px;
  }
}

.row {
  @include row;
}

.container {
  overflow-y: auto;
  @media only screen and (min-width: $sm-screen) {
    overflow-y: hidden;
    height: 100%;
  }
  form {
    width: 100%;
    margin: 0px;
  }
  .header {
    background-color: $grey;
    width: 100%;
    padding-bottom: 20px;
  }
  .main {
    width: 100%;
    @media only screen and (min-width: $sm-screen) {
      overflow-y: auto;
      &.edit {
        height: calc(100vh - 400px);
      }
    }
    .row {
      margin-bottom: 20px;
    }
  }
  .contact {
    padding: 20px;
  }
  .label {
    opacity: 0.5;
  }
  .state {
    position: relative;
    width: 100%;
    padding: 0px 20px 20px 20px;
    @media only screen and (min-width: $sm-screen) {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 300px;
      padding: 0px;
    }
  }
  .content {
    max-width: 1200px;
    padding: 0px 20px;
    margin: auto;
    .profile {
      max-width: 100%;
      @media only screen and (min-width: $sm-screen) {
        max-width: 55%;
      }
      @media only screen and (min-width: $md-screen) {
        max-width: 60%;
      }
      a {
        color: $primary;
      }
      &.quote {
        align-items: flex-end;
        margin-top: 10px;
        .input {
          &.edit {
            margin-bottom: 20px;
            padding-right: 12px;
          }
          @media only screen and (min-width: $sm-screen) {
            max-width: 46%;
          }
        }
      }
    }
    section {
      h3 {
        font-family: "Raleway-Bold";
        margin-bottom: 20px;
        font-size: 18px;
      }
      a {
        color: $primary;
      }
      border-bottom: 1px solid $grey;
      margin: 20px 0px;
      padding-bottom: 20px;
      width: 100%;
      @media only screen and (min-width: $sm-screen) {
        width: calc(100% - 320px);
      }
      &.questions {
        display: flex;
        flex-wrap: wrap;
      }
      .quotes {
        display: flex;
        flex-wrap: wrap;
        .quote {
          @include transition;
          background-color: $grey;
          padding: 8px 20px;
          color: $dark;
          text-decoration: none;
          border-radius: $radius;
          margin-right: 10px;
          margin-bottom: 10px;
          &:hover {
            background-color: rgba($primary, 0.3);
          }
        }
      }
      .btn-doc {
        margin: 20px 0px;
        @media only screen and (min-width: $md-screen) {
          margin: 20px;
        }
      }
      .dates {
        margin-right: 20px;
      } 
      .documents {
        margin-top: 20px;
        .doc {
          background-color: $grey;
          margin: 5px 0px;
          border-radius: 5px;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media only screen and (min-width: $sm-screen) {
            margin: 5px 20px 5px 0px;
          }
          a {
            color: $dark;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          svg {
            opacity: 0.5;
            cursor: pointer;
            &:hover {
              fill: $danger;
              opacity: 1;
            }
          }
        }
      }
      .note {
        svg {
          fill: $primary;
          transform: translate(3px, 3px);
        }
      }
    }
  }
  .contact {
    background-color: $dark;
    color: $white;
    width: 100%;
    padding-bottom: 30px;
    position: relative;
    h3 {
      font-family: "Raleway-Bold";
      font-size: 18px;
    }
    a {
      @include transition;
      display: flex;
      margin-top: 20px;
      color: $white;
      text-decoration: none;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      &:hover {
        color: $primary;
      }
    }
    @media only screen and (min-width: $sm-screen) {
      width: 300px;
      position: fixed;
      top: 85px;
      right: 40px;
      padding-bottom: 20px;
      border-radius:  15px;
    }
  }
}

