@import '../../../styles/variables.scss';

.toaster {
  @include transition;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  width: 280px;
  margin: 20px;
  padding: 10px;
  z-index: 3000;
  transform: translate(calc(50vw - 140px) , 100px);
  box-shadow: $shadow;
  background-color: $primary;
  color: $white;
  font-weight: bold;
  p {
    margin: 0px;
    text-align: center;
  }
  &.success {
    background-color: $primary;
    color: $white;
  }
  &.error {
    background-color: $error;
    color: $white;
  }
  &.isActive {
    transform: translate(calc(50vw - 140px) , 0px);
  }
}