@import '../../../../styles/variables.scss';

.input {
  width: 100%;
  input {
    @include transition;
    width: 100%;
    font-family: "Raleway-Regular";
    border-radius: 5px;
    padding: 11px;
    font-size: 14px;
    margin-top: 5px;
    border: 1px solid $primary;
    background-color: $white;
    transition: all 0.2s ease-in-out;
    border: 1px solid $grey-light;
    &.error {
      border: 1px solid $error;
    }
    &:focus {
      outline: none;
      background-color: $grey-extra-light;
    }
  }
  .error-message {
    color: $error;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
}